import { BACKGROUND } from '/assets/js/webgl/class/background.js';


window.WEBGL = {

	fov : 15,

	canvas : null,
	renderer : null,
	background : null,

	onInit(){

		//
		this.width  = window.innerWidth;
		this.height = window.innerHeight;

		//
		this.canvas = document.getElementById('webgl');
		this.renderer = new THREE.WebGLRenderer({
			'canvas'  : this.canvas,
			alpha     : false,
			antialias : false
		});
		this.renderer.setPixelRatio( RES );

		//
		this.aspect = this.width/this.height;
		this.far = Math.abs( -( this.height / 2) / Math.tan(( this.fov * Math.PI / 180) / 2) ) * RESCALE.r;

		//
		this.scene = new THREE.Scene();

		//
		this.p_camera = new THREE.PerspectiveCamera( this.fov, this.aspect, 1, this.far );
		this.scene.add( this.p_camera );
		this.p_camera.position.z = this.far;

		//
		this.setParm();

		//
		this.initFbo();
		this.initScreen();

		//
		// this.initGui();

		//
		this.onResize();

		//
		this.setTransform( ROUTE.next.type );

		//
		render();

	},

	screen : {
		mesh : null,
		segments : {
			w : 128,
			h : 128
		}
	},

	initFbo(){

		//
		this.fboFrontSide = new THREE.WebGLRenderTarget(
			this.width  * RES,
			this.height * RES,
			{
				depthBuffer   : true,
				stencilBuffer : true,
				minFilter: THREE.LinearFilter,
				magFilter: THREE.LinearFilter
			}
		);
		this.sceneFrontSide = new THREE.Scene();
		this.sceneFrontCamera = new THREE.PerspectiveCamera( this.fov, this.aspect, 1, this.far );
		this.scene.add( this.sceneFrontCamera );
		this.sceneFrontCamera.position.z = this.far;

		//
		this.background = new BACKGROUND( this.sceneFrontSide );
		this.background.init();

	},
	initScreen(){

		const geometry = new THREE.PlaneBufferGeometry(1, 1, 1);
		const material = new THREE.ShaderMaterial({
			vertexShader   : `
				varying vec2 vUv;
				void main(){
					vUv = uv;
					gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
				}
			`,
			fragmentShader : `
				varying vec2 vUv;
				uniform sampler2D tDiffuse;
				const float PI = 3.14159265;
				uniform float rgbPower;
				uniform vec3 rgbRatio;
				uniform float rgbDeg;
				uniform float alpha;
				void main() {
					float rad = radians( rgbDeg );
					vec2 shift = rgbPower * vec2( cos( rad ), sin( rad ) );
					float r = texture2D(tDiffuse, vUv - shift * rgbRatio.r ).r;
					float g = texture2D(tDiffuse, vUv - shift * rgbRatio.g ).g;
					float b = texture2D(tDiffuse, vUv - shift * rgbRatio.b ).b;
					float a = texture2D(tDiffuse, vUv ).a * alpha;
					gl_FragColor = vec4( r, g, b, a );
				}
			`,
			uniforms: {

				//
				tDiffuse : { value : this.fboFrontSide.texture },
				resolution : { value: { x: 0, y: 0 } },

				//
				alpha : { value: 1 },

				//
				rgbPower : { value: 0.0003 },
				rgbRatio : { value: { r: 0, g: 1, b: 2 } },
				rgbDeg   : { value: 45 }

			},
			transparent: true
		});
		material.alphaTest = 0.5;
		this.screen.mesh = new THREE.Mesh( geometry, material );
		this.scene.add( this.screen.mesh );

	},

	onResize(){

		this.renderer.setSize( window.innerWidth, window.innerHeight );

		//
		this.width  = window.innerWidth  * RESCALE.r;
		this.height = window.innerHeight * RESCALE.r;

		//
		this.far = Math.abs( -( this.height / 2) / Math.tan(( this.fov * Math.PI / 180) / 2) );

		//
		this.aspect = this.width/this.height;

		//
		if( this.p_camera ){
			this.p_camera.aspect = this.aspect;
			this.p_camera.position.z = this.far;
			this.p_camera.far = 0.1;
			this.p_camera.far = -this.far - this.width;
			this.p_camera.updateProjectionMatrix();
		}

		//
		if( this.sceneFrontCamera ){
			this.sceneFrontCamera.aspect = this.aspect;
			this.sceneFrontCamera.near = 1;
			this.sceneFrontCamera.updateProjectionMatrix();
		}

		//
		if( this.screen.mesh ){
			this.screen.mesh.scale.x = this.width;
			this.screen.mesh.scale.y = this.height;
			this.screen.mesh.material.uniforms.resolution.value.x = this.width;
			this.screen.mesh.material.uniforms.resolution.value.y = this.height;
		}

	},


	param : {
		camera : {
			position : {
				value : {
					x : -2,
					y : 2,
					z : 2.5
				},
				home : {
					x : -2,
					y : 6,
					z : 0
				},
				about : {
					x : -2,
					y : 2,
					z : 2.5
				}
			}
		},
		screen : {
			uniforms : {
				alpha : {
					value : 0.8,
					home  : 0.8,
					about : 0.8
				}
			}
		}
	},

	setParm(){
		// this.anim.oCamera.origin.position.x = this.anim.oCamera.from.position.x;
		// this.anim.oCamera.origin.position.y = this.anim.oCamera.from.position.y;
		// this.anim.oCamera.origin.position.z = this.anim.oCamera.from.position.z;
	},

	tween : [],
	onTransform( _key = 'home', _s = 3, _e = 'power2.inOut' ){
		this.background.onTransform( _key, _s, _e );
	},

	setTransform( _key = 'home' ){
		this.param.screen.uniforms.alpha.value = this.param.screen.uniforms.alpha[ _key ];
	},

	render(){

		//
		this.renderer.setRenderTarget( null );
		this.renderer.clear();
		this.renderer.render( this.scene, this.p_camera );

		//
		this.renderer.setRenderTarget( this.fboFrontSide );
		this.renderer.render( this.sceneFrontSide, this.sceneFrontCamera );

		const mx = window.innerWidth * RESCALE.r * 0.1 * mouseEvent.anims.slow.cx * -1;
		const my = window.innerWidth * RESCALE.r * 0.1 * mouseEvent.anims.slow.cy;

		//
		this.sceneFrontCamera.position.x = this.param.camera.position.value.x * 10 + mx;
		this.sceneFrontCamera.position.y = this.param.camera.position.value.y * 10 + my * 0.1;
		this.sceneFrontCamera.position.z = this.param.camera.position.value.z * 10;
		this.sceneFrontCamera.lookAt( 0,0,0 ); 

		//
		this.screen.mesh.material.uniforms.alpha.value = this.param.screen.uniforms.alpha.value;

		//
		this.background.update();

	}

}

























