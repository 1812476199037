//
window.getParam = function(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}



//
window.padZero = function(n, l){
	return ( Array(l).join('0') + String(n) ).slice( -l );
}



//
window.killTween = function(tween){
	const isA = Array.isArray(tween);
	if( isA ){
		for (let i = 0; i < tween.length; i++) {
			if( tween[i] ) tween[i].kill();
		}
		tween = [];
	} else {
		if( tween ) tween.kill(); tween = null;
	}
}



//
window.clamp = function(number, min, max) {
  return Math.max(min, Math.min(number, max));
}



//
window.getImageSrc = function( d1x, d2x, mob, webp ){

	let src = d1x;

	if( DETECT.retina ){
		src = d2x;
	}

	if( DETECT.device.tablet ){
		src = d2x;
	}

	if( DETECT.device.mobile ){
		src = mob;
	}

	if( DETECT.webp && webp ){
		src = src + '.webp';
	}

	return src;

};



// window.stylePath = {

// 	onInit(){

// 		this.$pathB = document.querySelectorAll('.splash-B .js-path');
// 		this.onConvert();

// 	},

// 	onConvert(){
// 		for (var i = 0; i < this.$pathB.length; i++) {
// 			const polygon = this.$pathB[i];
// 			const points = polygon.getAttribute('points');
// 			var p = points.split(/\s+/);
// 			var path = "";
// 			for( var n = 0, len = p.length; n < len; n++ ){
// 				path += (i && "L" || "M") + p[n];
// 			}
// 		}
// 	}

// }
// stylePath.onInit();




window.styleDom = {

	onInit(){

		this.$col = document.querySelectorAll('.js-col');
		this.onResize();

	},

	onResize(){

		//
		let _h = [];
		for (var i = 0; i < this.$col.length; i++) {
			const el = this.$col[i];
			el.style.height = 'auto';
			const rect = this.$col[i].getBoundingClientRect();
			_h.push( rect.height );
		}

		//
		let maxHeight = Math.max(..._h);
		for (var i = 0; i < this.$col.length; i++) {
			const el = this.$col[i];
			el.style.height = maxHeight + 'px';
		}

	}

}

















