//
const environment = process.env.NODE_ENV || 'development';
const envSet = require(`~/api/env.${environment}.js`)



//
const $root = document.getElementById('root');
let rootInterval;
window.addEventListener('load' , e =>{

	//
	RESOURCE.onInit();

	//
	// if( environment === 'development' ){
	// 	$html.classList.add('l1','l2','l3');
	// }

	//
	rootInterval = setInterval(() => {
		if( $root.innerText === ':root{--vh:1vh}' ){
			$root.innerText = ':root {--vh:'+ window.innerHeight/100 +'px;}';
		}
	}, 100 );

	//
	styleDom.onInit();

	//
	addEvents();

});



//
let resizeWebGL;
let resizeDom;
window.addEventListener('resize' , e =>{

	//
	$root.innerText = ':root {--vh:'+ window.innerHeight/100 +'px;}';

	//
	clearTimeout( resizeDom );
	resizeDom = setTimeout(()=>{
		styleDom.onResize();
	}, 100);

	//
	clearTimeout( resizeWebGL );
	resizeWebGL = setTimeout(()=>{
		WEBGL.onResize();
	}, 300);

});



//
window.render = () => {
	requestAnimationFrame( render );
	WEBGL.render();
	if( mouseEvent.ready ) mouseEvent.onUpdate();
};



// //
// window.onloaded = function(){
// 	WEBGL.onInit();
// }



//
window.mouseEvent = {

	ready : false,

	x  : 0,
	y  : 0,
	cx : 0,
	cy : 0,
	ax : 0, // 絶対位置
	ay : 0, // 絶対位置

	start : {
		x  : 0,
		y  : 0,
	},

	ing : {
		x  : 0,
		y  : 0,
	},

	dist : {
		x  : 0,
		y  : 0,
	},

	onStart( e ){
		let x = e.clientX;
		let y = e.clientY;
		this.start.x = x;
		this.start.y = y;
		this.dist.x  = 0;
		this.dist.y  = 0;
		this.active = true;
	},

	cursor : {
		el : null,
		bg : null,
		ing : false,
		enter : null,
		leave : null,
	},

	once(){

		const _this = this;

		//
		this.cursor.el = document.querySelector('.ui-cursor');

		//
		this.x  = window.innerWidth/2;
		this.y  = window.innerHeight/2;
		this.cx = (this.x - window.innerWidth/2) / window.innerWidth;
		this.cy = (this.y - window.innerHeight/2) / window.innerHeight;

		//
		Object.keys( this.anims ).forEach( (key) => {
			this.array.push( key );
		});

		//
		this.getSpeed();

		//
		this.ready = true;

	},

	onEnterCursor(type){
		const _this = this;
		if( this.cursor.leave ) this.cursor.leave.kill();
		this.cursor.enter = gsap.to( this.cursor.anim, {
			duration : .3,
			scale : 1,
			onStart(){
				_this.cursor.ing = true;
			},
			onComplete(){

			}
		});
	},
	onLeaveCursor(type){
		const _this = this;
		if( this.cursor.enter ) this.cursor.enter.kill();
		this.cursor.leave = gsap.to( this.cursor.anim, {
			duration : .3,
			scale : 0,
			onStart(){

			},
			onComplete(){
				_this.cursor.ing = false;
			}
		});
	},

	onMove( e ){
		const _this = this;

		//
		this.x  = e.clientX;
		this.y  = e.clientY;

		//
		this.ing.x  = this.x;
		this.ing.y  = this.y;
		this.dist.x = this.start.x - this.x;
		this.dist.y = this.start.y - this.y;

		//
		this.cx = (this.x - window.innerWidth/2) /  window.innerWidth;
		this.cy = (this.y - window.innerHeight/2) / window.innerHeight;
		if( this.cx < 0 ){ this.ax = -1 } else if( this.cx > 0 ){ this.ax = 1 } else { this.ax = 0 }
		if( this.cy < 0 ){ this.ay = 1 } else if( this.cx > 0 ){ this.ay = -1 } else { this.ay = 0 }
		this.speed.currentEvent = e;

	},

	onEnd(){
		this.dist.x = 0;
		this.dist.y = 0;
		this.active = false;
	},

	speed : {
		prevEvent    : null,
		currentEvent : null,
		x     : 0,
		y     : 0,
		xy    : 0,
		delay : 50,
	},

	camera : { x: 0, y: 0, },

	array  : [],

	anims : {
		normal : {
			x  : 0,
			y  : 0,
			sx : 0, // shader
			sy : 0, // shader
			cx : 0, 
			cy : 0, 
			center : { x  : 0, y  : 0, ease : 0.05, },
			speed : { x  : 0, y  : 0, xy : 0, ease : 0.1, },
			delta : { x : 0, y : 0, ease : 0.05, },
			// rotaion : { x : 0, y : 0, z : 0, },
		},
		slow : {
			x  : 0,
			y  : 0,
			sx : 0, // shader
			sy : 0, // shader
			cx : 0, 
			cy : 0, 
			center : { x  : 0, y  : 0, ease : 0.025/2, },
			speed : { x  : 0, y  : 0, xy : 0, ease : 0.025/2, },
			delta : { x : 0, y : 0, ease : 0.05/2, },
			rotaion : { x : 0, y : 0, z : 0, },
		}
	},

	getSpeed(){

		setInterval(() => {
			if( this.speed.prevEvent && this.speed.currentEvent){
				const x = this.speed.currentEvent.screenX - this.speed.prevEvent.screenX;
				const y = this.speed.currentEvent.screenY - this.speed.prevEvent.screenY;
				const ax = Math.abs( x );
				const ay = Math.abs( y );
				this.speed.x  = x;
				this.speed.y  = y;
				this.speed.xy = Math.sqrt( x * x + y * y );
			}
			this.speed.prevEvent = this.speed.currentEvent;
		}, this.speed.delay );

	},

	onUpdate(){

		for (let i = 0; i < this.array.length; i++) {

			const key = this.array[i];
			const v   = this.anims[key];

			//===
			v.speed.x  += ( this.speed.x - v.speed.x ) * v.speed.ease;
			v.speed.y  += ( this.speed.y - v.speed.y ) * v.speed.ease;
			v.speed.xy += ( this.speed.xy - v.speed.xy ) * v.speed.ease;
			v.center.x += ( this.cx - v.center.x ) * v.center.ease;
			v.center.y += ( this.cy - v.center.y ) * v.center.ease;
			v.delta.x  += ( this.x - v.delta.x + v.speed.x ) * v.delta.ease;
			v.delta.y  += ( this.y - v.delta.y + v.speed.y ) * v.delta.ease;

			//===
			v.x  = v.delta.x;
			v.y  = v.delta.y;
			v.sx = clamp( v.delta.x/window.innerWidth, 0, 1 );
			v.sy = clamp( 1 - v.delta.y/window.innerHeight, 0, 1);
			v.cx = (v.x - window.innerWidth/2) / window.innerWidth;
			v.cy = (v.y - window.innerHeight/2) / window.innerHeight;

			if( v.rotaion ){
				gsap.to( v.rotaion, {
					z : v.speed.x,
					duration : 2,
					ease : 'back.out(4)',
				});
			}

		}

		if( this.cursor.el ) this.cursor.el.style.transform = "translate3d("+ this.anims.cursor.x +"px, "+ this.anims.cursor.y +"px, 0)";

	}

};
mouseEvent.once();



//
window.addEvents = function(){

	if( !DETECT.device.any ){

		//
		window.addEventListener( 'mousedown' , function(e) {
			mouseEvent.onStart(e);
		},  { passive: false });

		//
		window.addEventListener( 'mousemove' , function(e) {
			mouseEvent.onMove(e);
		},  { passive: false });

		//
		window.addEventListener( 'mouseup' , function(e) {
			mouseEvent.onEnd();
		},  { passive: false });

		//
		document.addEventListener( 'mouseleave' , function(e) {
			mouseEvent.onEnd();
		});

	}

}


























