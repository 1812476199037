//===============
// THREE
//===============
import * as THREE from               '~/_modules/three.js-r137/build/three.module.js';
import { DRACOLoader } from          '~/_modules/three.js-r137/examples/jsm/loaders/DRACOLoader.js';
import { GLTFLoader } from           '~/_modules/three.js-r137/examples/jsm/loaders/GLTFLoader.js';
import { Reflector } from            '~/_modules/three.js-r137/examples/jsm/objects/ReflectorBlur.js';
import * as BufferGeometryUtils from '~/_modules/three.js-r137/examples/jsm/utils/BufferGeometryUtils.js';


//===============
// GSAP
//===============
import { gsap } from "gsap/dist/gsap";
import { DrawSVGPlugin } from "gsap/dist/DrawSVGPlugin";


if( process.browser ) {


	// GSAP
	window.gsap = gsap;
	gsap.registerPlugin( DrawSVGPlugin );
	gsap.config({ nullTargetWarn: false });


	// THREE
	window.THREE = THREE;
	window.GLTFLoader = GLTFLoader;
	window.Reflector = Reflector;
	window.BufferGeometryUtils = BufferGeometryUtils;
	window.dracoLoader = new DRACOLoader();
	dracoLoader.setDecoderPath( 'https://cdn.jsdelivr.net/npm/three@0.137.0/examples/js/libs/draco/' );

	//
	require('../assets/js/0.var.js');
	require('../assets/js/1.functions.js');
	require('../assets/js/2.route.js');
	require('../assets/js/3.events.js');
	require('../assets/js/4.splash.js');
	require('../assets/js/5.pwa.js');

	//
	require('../assets/js/webgl/0.resource.js');
	require('../assets/js/webgl/1.init.js');

}