import * as a from "./actions";
import * as g from "./getters";
import m from "./mutations";

//
export const state = () => ({
	page : null,
	history : {
		old : null,
		now : null,
	}
})

export const mutations = {
	...m
}

export const actions = {
	...a
}

export const getters = {
	...g
}