const environment = process.env.NODE_ENV || 'development';
const envSet = require(`~/api/env.${environment}.js`)
const rand = Math.random() * 999999;
if( "serviceWorker" in navigator && envSet.pwa ) {
	window.addEventListener('load', function () {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.register('/sw.js?'+rand)
			.then(
			function (registration) {
				if (typeof registration.update == 'function') {
					registration.update();
				}
			})
			.catch(function (error) {
				console.log("Error Log: " + error);
			});
		}
	});
}