




window.ROUTE = {

	//
	firsttime : true,

	//
	name : 'home',
	type : 'home',
	slug : null,

	//
	prev    : {},
	current : {},
	next    : {},

	//
	pages : {
		home : 0,
		about : 0
	},

	//
	tween : [],

	//
	onMounted( device, data ){

		//
		this.prev = this.current;
		this.current = data.current;

		//
		if( !this.firsttime ){
			this.onTransition( this.current.type );
		} else {
			switch( this.current.type ){
				case 'home':
					this.pages.home  = 1;
					this.pages.about = 0;
				break;
				default:
					this.pages.home  = 0;
					this.pages.about = 1;
				break;
			}
		}

	},

	onTransition( type ){

		console.log('[onTransition]',type);

		//
		killTween( this.tween ); this.tween = [];
		switch( type ){
			case 'home':
				const tl1 = gsap.to( this.pages, {
					home : 1,
					about : 0,
					duration : 3,
					ease : 'expo.out'
				});
				this.tween.push( tl1 );
			break;
			default:
				const tl2 = gsap.to( this.pages, {
					home : 0,
					about : 1,
					duration : 3,
					ease : 'expo.out'
				});	
				this.tween.push( tl2 );	
			break;
		}

	},

	isStaicRouteTo : false,
	isStaicSwitchLang : false,

	onStaicRouteTo( path, $router, type = this.current.type ){

		const _this = this;

		//
		let _type = type;

		//
		if( _type === 'lang' ){

			//
			this.isStaicSwitchLang = true;
			$html.classList.add('is-lang');
			_type = this.current.type;

			//
			clearTimeout( this.anim.lang.timer );
			this.anim.lang.timer = setTimeout(()=>{
				_this.isStaicRouteTo = true;
				_this.next = { type : _type };
				$router.push({ path : path });
			}, 200);

		} else {

			//
			this.isStaicRouteTo = true;
			this.next = { type : _type };
			$router.push({ path : path });

		}


	},

	anim : {
		lang : {
			timer : null
		},
		enter : {
			timer : null
		},
		leave : {
			timer : null
		}
	},

	leave( el, done ){

		//
		this.firsttime = false;

		//
		if( this.isStaicRouteTo ){

			//
			SPLASH.onInit( this.next.type, false );

			//
			this.onTransition( this.next.type );

			// WEBGL.onTransform( this.next.type, 3, 'expo.out' );

		}

		//
		$html.classList.add('is-leave');

		//
		clearTimeout( this.anim.leave.timer );
		this.anim.leave.timer = setTimeout(()=>{
			done();
		}, 600 );


	},
	beforeEnter( el ){
		console.log('[beforeEnter]');
	},
	enter( el, done ){

		console.log( '[enter]', this.current.type );

		if( !this.isStaicRouteTo ){
			SPLASH.onInit( this.current.type, false );
			// WEBGL.onTransform( this.current.type, 3, 'expo.out' );
			// WEBGL.logo.onTransform( this.current.type, 3, 'expo.out', 'power2.inOut' );
		}

		//
		clearTimeout( this.anim.enter.timer );
		this.anim.enter.timer = setTimeout(()=>{
			$html.classList.remove('is-leave','is-lang');
		}, 50 );

		//
		this.isStaicRouteTo = false;
		this.isStaicSwitchLang = false;

		//
		styleDom.onInit();

		//
		done();

	},
	afterEnter( el ){

		console.log('[afterEnter]');

	}
}














