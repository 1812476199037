window.RES = window.devicePixelRatio;


window.COLOR_ORIGIN = {
	bg : {
		three : new THREE.Color('#000')
	},
	black : {
		three : new THREE.Color('#111')
	},
	floor : {
		three : new THREE.Color('#151515')
	}
}


window.RESCALE = {
	r : 0.1
};
