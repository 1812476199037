//
//
//
//
//
//
//
//
//
//
//


import { mapState, mapGetters } from 'vuex';

export default {

	name: 'default',

	data(){
		return {
			page : this.onRouted( this.$route, this.$route ),
		}
	},

	watch : {
		$route(to, from) {
			this.page = this.onRouted( to, from );
		}
	},

	computed: {
		...mapGetters([
			// 'ui',
			// 'projects',
			// 'project_selected_slug',
			// 'archive',
			// 'archive_origin_len'
		]),
	},

    head() {
        return {
            htmlAttrs: {
                lang: this.$i18n.locale
            }
        }
    },

	methods :{

		getType( name ){
			if( !name ){
				return 'notfound';
			} else {
				let ja = name.replace(/___ja/g, '');
				let rename = ja.replace(/___en/g, '');
				switch( rename ){
					case 'index':
						return 'home';
					break;
					case 'home':
						return 'home';
					break;
					case 'about':
						return 'about';
					break;
					default:
						return 'other';
					break;
				}
			}
		},

		onRouted(to,from){
			return {
				current : {
					slug : to.params.slug,
					name : this.getType(to.name)
				},
				prev :{
					slug : from.params.slug,
					name : this.getType(from.name)
				} 
			}
		}

	},

	mounted(){
		this.$nextTick(() => {

			//
			const _this = this;

			//
			window.__WT__  = 0;
			window.__WH__  = window.innerHeight;
			window.__WW__  = window.innerWidth;
			window.__SW__  = window.screen.width > window.outerWidth ? window.screen.width : window.outerWidth;
			window.__SH__  = window.screen.height > window.outerHeight ? window.screen.height : window.outerHeight;

			//
			window.$html = document.querySelector('html');

			//
			window.DETECT = {

				spec : {
					str   : null,
					score : 0,
				},

				webp    : false,
				touch   : false,
				retina  : false,
				webgl   : true,
				browser : null,
				legacy  : false,
				lowspec : false,

				os : {
					win     : _this.$device.isWindows,
					mac     : _this.$device.isMacOS,
					android : _this.$device.isAndroid,
					ios     : _this.$device.isIos,
					unknown : false,
				},

				device : {
					mobile  : false,
					tablet  : false,
					ipadpro : false,
					desktop : true,
					laptop  : false,
					any     : false, // tablet or mobile
				},

				ua     : window.navigator.userAgent.toLowerCase(),
				ua_ver : window.navigator.appVersion.toLowerCase(),

				once(){

					//
					if (window.ontouchstart === null) {
						this.touch = true;
					}

					//
					if( window.devicePixelRatio > 1.5 ){
						this.retina = true;
					}

					//
					if ( window.navigator.platform.indexOf("Win") != -1 ) {
						this.os.win = true;
						$html.classList.add('is-win');
					}

					//
					this.canvas = document.createElement("canvas");

					//
					this.detectSpec();
					this.detectDevide();
					this.detectBrows();
					// this.detectWebGL();
					this.detectWebp();

				},

				getUA(str) {
					return window.navigator.userAgent.toLowerCase().indexOf(str) != -1;
				},

				detectDevide(){

					if( _this.$device.isMobile || __WW__ < 640 ){

						this.device.any = true;
						this.device.mobile = true;
						this.device.tablet = false;
						this.device.desktop = false;
						$html.classList.add('is-m','is-any');

					} else if( _this.$device.isTablet || __WW__ < 1024 || ( !this.getUA('ipad') && ( this.getUA('macintosh') && 'ontouchend' in document )) ){

						this.device.any = true;
						this.device.mobile = false;
						this.device.tablet = true;
						this.device.desktop = false;
						$html.classList.add('is-t','is-any');
						if( __WW__ > 1024 ){
							this.device.ipadpro = true;
							$html.classList.add('is-large-tablet');
						}

					} else {

						this.device.any = false;
						this.device.mobile = false;
						this.device.tablet = false;
						this.device.desktop = true;
						$html.classList.add('is-d');
						if( __WW__ <= 1366 ){
							this.laptop = true;
						}

					}

				},

				detectSpec(){

					this.core = window.navigator.hardwareConcurrency ? window.navigator.hardwareConcurrency : 4;

					if( this.retina ){

						// Laptop
						this.spec.str = 'Laptop';
						this.spec.score = 7;

						// Laptop HiEnd Corei7
						if( __SW__ <= 1680 && this.core >= 8 ){
							this.spec.str  = 'Laptop HiEnd, Corei7 or higher';
							this.spec.score = 8;
						}

						if( ( ( __WW__ === __SW__ ) && __SW__ <= 1680 ) && this.core >= 6 ){
							this.spec.str  = 'Laptop MidEnd, 15 Fullscreen, Corei5 or higher';
							this.spec.score = 7;
						}

						if( ( __SW__ <= 1440 ) && this.core >= 8 ){
							this.spec.str  = 'Laptop MidEnd, 13 Fullscreen, Corei7 or higher';
							this.spec.score = 8;
						}

						// Laptop HiEnd
						if( this.core >= 10 ){
							this.spec.str  = 'Laptop HiEnd';
							this.spec.score = 8;
						}

						// Desktop Corei3
						if( __SW__ > 1680 && this.core >= 4 ){
							this.spec.str  = 'Desktop MidEnd, Corei5 or higher';
							this.spec.score = 8;
						}

						// Desktop Corei5
						if( __SW__ > 1680 && this.core >= 6 ){
							this.spec.str  = 'Desktop MidEnd, Corei5 or higher';
							this.spec.score = 9;
						}

						// Desktop Corei7
						if( __SW__ > 1680 && this.core >= 8 ){
							this.spec.str  = 'Desktop HiEnd, Corei7 or higher';
							this.spec.score = 10;
						}

					} else {

						// 1x
						this.spec.str = 'Other';
						this.spec.score = 9;

					}

					//
					if( this.device.phone ){
						this.spec.str = 'Mobile';
					} else if( this.device.tablet ){
						this.spec.str = 'Tablet';
					}

				},

				detectBrows(){
					if (this.ua.indexOf('edge') !== -1){this.browser = "edge"; this.legacy  = true; } else if (this.ua.indexOf("iemobile") !== -1){this.browser = "other"; this.legacy  = true; this.lowspec = true; } else if (this.ua.indexOf('trident/7') !== -1){this.browser = "other"; this.legacy  = true; this.lowspec = true; } else if (this.ua.indexOf("msie") !== -1 && this.ua.indexOf('opera') === -1) {this.browser = "msie"; this.legacy  = true; this.lowspec = true; } else if (this.ua.indexOf('chrome')  !== -1 && this.ua.indexOf('edge') === -1){this.browser = "chrome"; }else if (this.ua.indexOf('safari')  !== -1 && this.ua.indexOf('chrome') === -1){this.browser = "safari"; }else if (this.ua.indexOf('opera')   !== -1){this.browser = "opera"; }else if (this.ua.indexOf('firefox') !== -1){this.browser = "firefox"; }else if (this.ua.indexOf(' UCBrowser/') >= 0 ){this.browser = "other"; }else{this.browser = "other"; }
					if( !this.device.any ){
						$html.dataset.brows = this.browser;
					}
				},

				detectWebp(){
					if( this.canvas.toDataURL('image/webp').indexOf('data:image/webp') == 0 ){
						this.webp = true;
					} else {
						this.webp = false;
					}
				},
			}

			//
			//=====
			if( __WW__ === 0 ){ location.reload(); } else { DETECT.once(); }


			//=====
			window.IS_REDUCED = false;
			const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
			if( !mediaQuery || mediaQuery.matches || getParam('motion') === 'reduced' ) {
				IS_REDUCED = true;
				$html.classList.add('is-reduced');
			}


		});
	}

}

