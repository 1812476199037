


//
const date = new Date();
window.TIMER = {
	wait  : 750,
	start : date.getTime()
};




//
window.RESOURCE = {

	textures : {
		floor : {
			normal : {
				tex : null,
				src : {
					d2x : './assets/tex/floor@2x.png',
					d1x : './assets/tex/floor@1x.png',
					mob : './assets/tex/floor@1x.png'
				}
			}
		},
		logo : {
			normal : {
				tex : null,
				src : {
					d2x : './assets/tex/logo@2x.png',
					d1x : './assets/tex/logo@1x.png',
					mob : './assets/tex/logo@1x.png'
				}
			}
		}
	},

	models : {
		logoSet : {
			geometry : null,
			mergeGeometry : true,
			src  : './assets/models/logo_all_set_3.glb'
		},
		logoCai : {
			geometry : null,
			mergeGeometry : true,
			src  : './assets/models/logo_cai_4.glb'
		},
	},

	progress : 0,

	onInit(){

		//
		const _this = this;

		//
		this.loadingManagerFirst = new THREE.LoadingManager();
		this.loadingManagerFirst.onLoad = () => {

			//
			const date = new Date();

			//
			TIMER.onload = date.getTime() - TIMER.start;


			//==================================================
			// 初期ロードから TIMER.wait ms を超えない場合
			//==================================================
			if( TIMER.onload < TIMER.wait ){

				console.log(' Not Over TIMER.wait ');

				setTimeout(()=>{
					$html.classList.add('l1');
				}, TIMER.wait - TIMER.onload );

				setTimeout(()=>{
					$html.classList.add('l2');
				}, TIMER.wait - TIMER.onload + 500 );

				setTimeout(()=>{
					$html.classList.add('l3');
				}, TIMER.wait - TIMER.onload + 1500 );


			} else {

				console.log(' Over TIMER.wait ');

				$html.classList.add('l1');

				setTimeout(()=>{
					$html.classList.add('l2');
				}, 500 );

				setTimeout(()=>{
					$html.classList.add('l3');
				}, 1500 );

			}


			//
			WEBGL.onInit();

		}

		//
		// this.loadingManagerFirst.onProgress = function ( url, itemsLoaded, itemsTotal ) {};
	
		//
		this.onLoadModels();
		this.onLoadTextures();

	},

	onLoadModels(){

		const _this = this;
		Object.keys( this.models ).forEach( (key) => {
			const v = _this.models[key];
			const loader = new GLTFLoader( this.loadingManagerFirst );
			loader.setDRACOLoader( dracoLoader );
			loader.load( v.src,
				gltf => {

					// if( v.mergeGeometry ){

					const geometries = [];
					gltf.scene.traverse( child => {
						if( child.type === 'Mesh' ) {
							v.material = child.material;
							geometries.push(child.geometry);
							if( child.geometry ) child.geometry.dispose();
							if( child.material ) child.material.dispose();
							if( child.texture ) child.texture.dispose();
						}
					});
					const geometry = BufferGeometryUtils.mergeBufferGeometries( geometries, true );
					// geometry.applyMatrix4( new THREE.Matrix4().makeRotationX( Math.PI / 2 ) );
					geometry.name = key;
					geometry.center();
					v.geometry = geometry;

					// } else {

					// 	const geometries = {};
					// 	gltf.scene.traverse( child => {
					// 		if( child.type === 'Mesh' ) {
					// 			geometries[child.name] = {
					// 				name : child.name,
					// 				geometry : child.geometry
					// 			};
					// 			if( child.geometry ) child.geometry.dispose();
					// 			if( child.material ) child.material.dispose();
					// 			if( child.texture ) child.texture.dispose();
					// 		}
					// 	});
					// 	v.geometries = geometries;

					// }

				},
				xhr => {
				},
				error => {
				}
			);

		});

	},
	onLoadTextures(){

		const _this = this;
		Object.keys( this.textures ).forEach( (key) => {
			const v = _this.textures[key];
			Object.keys( v ).forEach( ( k ) => {
				const w = v[k];
				const src = getImageSrc( w.src.d2x, w.src.d1x, w.src.mob, true ); 
				w.tex = new THREE.TextureLoader( _this.loadingManagerFirst ).load( src );
			});
		});

	}

}










