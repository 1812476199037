



window.SPLASH = {

	delay : 0.05,

	once(){

		//
		this.$A = document.querySelector('.splash-A');

		//
		this.$B = document.querySelector('.splash-B');

	},

	onInit( type, firsttime = false ){

		const _this = this;

		//
		killTween( this.tweens ); this.tweens = [];

		//
		if( type === 'about' && firsttime ){
			this.setHide( this.$B );
		}

		//
		switch( type ){
			case 'home':
				this.onShow( this.$B, 0.1, 0.3, 2 );
			break;
			case 'about':
				// this.onHide( this.$B );
			break;
		}

	},

	tweens : [],

	onShow( _el = this.$A, _d1 = 0.05, _d2 = 0, _s = 1.2, _e = 'expo.out' ){

		//
		console.log('[onShow]');

		//
		const line = _el.querySelectorAll('.logo-line path');
		const translate = _el.querySelectorAll('.logo-t');

		//
		// killTween( this.tweens ); this.tweens = [];

		//
		for (var i = 0; i < line.length; i++) {
			var tl = gsap.to( line[i] ,{
				duration : _s,
				ease : _e,
				delay : _d1 * i + _d2,
				drawSVG  : '0% 100%',
			});
			this.tweens.push( tl );
		}
		for (var i = 0; i < translate.length; i++) {
			var tl = gsap.to( translate[i] ,{
				duration : _s,
				ease : _e,
				delay : _d1 * i + _d2,
				x : 0,
				y : 0
			});
			this.tweens.push( tl );
		}

	},

	onHide( _el = this.$A, _d1 = 0.05, _d2 = 0, _s = 1.2, _e = 'expo.out' ){

		//
		console.log('[onHide]');

		//
		const line = _el.querySelectorAll('.logo-line path');
		const translate = _el.querySelectorAll('.logo-t');

		//
		// killTween( this.tweens ); this.tweens = [];

		//
		for (var i = 0; i < line.length; i++) {
			var tl = gsap.to( line[i] ,{
				duration : _s,
				ease : _e,
				delay : _d1 * i + _d2,
				drawSVG  : '100% 100%',
			});
			this.tweens.push( tl );
		}

	},

	setHide( _el = this.$A ){

		//
		console.log('[onHide]');

		//
		const line = _el.querySelectorAll('.logo-line path');
		const translate = _el.querySelectorAll('.logo-t');

		//
		// killTween( this.tweens ); this.tweens = [];

		//
		for (var i = 0; i < line.length; i++) {
			gsap.set( line[i] ,{
				drawSVG  : '100% 100%',
			});
		}

	}

}

SPLASH.once();
SPLASH.onInit( ROUTE.current.type, true );
SPLASH.onShow( SPLASH.$A, 0.05, 0, 1 );
























































































































































































































































































































