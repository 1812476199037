export class BACKGROUND {

	constructor( scene ){

		this.scene = scene;

		this.stage = new THREE.Group();

		this.logos = {
			center : null,
			back   : null,
			left   : null,
			right  : null,
			boundingBox : {
				x : 0,
				y : 0,
				z : 0,
			}
		};

		this.parts = {
			front : null,
			boundingBox : {
				x : 0,
				y : 0,
				z : 0,
			}
		};

		this.logoSet = {
			group  : new THREE.Group(),
			all    : { mesh : null, material : null, geometry : null },
			cai    : { mesh : null, material : null, geometry : null },
			floor  : { mesh : null, material : null, geometry : null },
			mirror : { mesh : null, material : null, geometry : null }
		};

		//
		this.lights = { top : null };

		//
		this.tween = [];

		//
		this.param = {
			logoSet : {
				group : {
					scale : {
						value : 1.3,
						home  : 2,
						about : 1.3
					},
					position : {
						value : {
							x : 0,
							y : 0,
							z : -0.1
						},
						home : {
							x : 0,
							y : -0.3,
							z : -0.05
						},
						about : {
							x : -0.1,
							y : -0.05,
							z : 0.1
						},
					},
					rotation : {
						value : {
							x : 0,
							y : 90,
							z : 0,
						},
						home : {
							x : 0,
							y : 90,
							z : 0
						},
						about : {
							x : 0,
							y : 30,
							z : 0
						}
					}
				},
				all : {
					scale : 1,
					roughness : {
						value : 0.5,
						home  : 0.5,
						about : 0
					},
					metalness : {
						value : 0,
						home  : 0,
						about : 0
					},
					rotation : {
						value : {
							x : -90,
							y : 0,
							z : 0
						},
						home : {
							x : -90,
							y : 0,
							z : 0
						},
						about : {
							x : -90,
							y : 0,
							z : 0
						}
					},
					position : {
						value : {
							x : 0.3,
							y : 0.87,
							z : 0.1,
						},
						home : {
							x : 0.3,
							y : 0.87,
							z : 0.1,
						},
						about : {
							x : 0.3,
							y : -1.62,
							z : 0.1
						}
					}
				},
				cai : {
					scale : 1.12,
					// scale : 2.3,
					roughness : {
						value : 0.5,
						home  : 0,
						about : 0.5
					},
					metalness : {
						value : 0,
						home  : 0,
						about : 0
					},
					rotation : {
						value : {
							x : 0,
							y : 180,
							z : 0
						},
						home : {
							x : 0,
							y : 180,
							z : 0
						},
						about : {
							x : 0,
							y : 180,
							z : 0
						}
					},
					position : {
						value : {
							x : -0.08,
							y : 1.09,
							z : 0,
						},
						home : {
							x : -0.08,
							y : -1.09,
							z : 0
						},
						about : {
							x : -0.0,
							y : 1.09,
							z : 0,
						}
					}
				},
				floor : {
					scale : 33.80,
					rotation : {
						value : {
							x : -90,
							y : 0,
							z : 180
						}
					},
					position : {
						value : {
							x : 0,
							y : 0.01,
							z : 0
						}
					},
					uniforms : {
						color : {
							// value : '#111'
							value : '#fff'
						},
						alpha : {
							// value : 0.8,
							// home  : 0.8,
							// about : 0.75
							value : .9,
							home  : .9,
							about : .9
						},
						dark : {
							// value : 0,
							// home  : 0,
							// about : 0
							value : 0.05,
							home  : 0.05,
							about : 0.05
						},
						mixRatio : {
							value : 0,
							home  : 0,
							about : 1
						},
						mixRatioColor : {
							value : 0,
							home  : 0,
							about : 0
						}
					}
				}
			},
			light : {
				top : {
					position : {
						value : {
							x : 0,
							y : 1,
							z : 1
						},
						home : {
							x : 0,
							y : 1,
							z : 1
						},
						about : {
							x : 0,
							y : 1,
							z : 2
						}
					},
					intensity : 2,
					distance : 2.5,
					penumbra : 1,
					decay : 1
				}
			}
		};

		//
		if( DETECT.device.mobile ){
			const _s = 1440/window.innerWidth;
			this.param.logoSet.group.scale.home  = _s;
			this.param.logoSet.group.scale.about = _s/2;
		}

	}

	init(){

		//==========================
		// ALL
		//==========================
		this.logoSet.all.material = new THREE.MeshStandardMaterial({
			roughness : .5,
			metalness : 0,
			color : COLOR_ORIGIN.black.three,
		});
		this.logoSet.all.mesh = new THREE.Mesh( RESOURCE.models.logoSet.geometry, this.logoSet.all.material );
		this.logoSet.group.add( this.logoSet.all.mesh );

		//==========================
		// CAI
		//==========================
		this.logoSet.cai.mesh = new THREE.Mesh( RESOURCE.models.logoCai.geometry, this.logoSet.all.material );
		this.logoSet.group.add( this.logoSet.cai.mesh );


		//==========================
		// FLOOR
		//==========================
		this.logoSet.floor.material = new THREE.ShaderMaterial({
			side : THREE.DoubleSide,
			vertexShader   : `
				varying vec2 vUv;
				void main(){
					vUv = uv;
					gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
				}
			`,
			fragmentShader : `
				varying vec2 vUv;
				uniform sampler2D tFloor;
				uniform sampler2D tLogo;
				uniform vec2 scale;
				uniform vec3 color;
				uniform float alpha;
				uniform float mixRatioColor;
				uniform float mixRatio;
				uniform float dark;
				void main() {

					//
					vec2 resize = 1.0/scale;
					vec2 offset = resize * ( scale - 1.0 ) * 0.5;

					//
					vec2 uv_scale = vUv * resize + offset;
					vec2 uv_repeat = vec2( uv_scale.x, uv_scale.y );
					uv_repeat.x = uv_repeat.x > 1.0 ? 2.0 - uv_repeat.x : uv_repeat.x;
					uv_repeat.x = uv_repeat.x < 0.0 ? -1.0 * uv_repeat.x : uv_repeat.x;
					uv_repeat.y = uv_repeat.y > 1.0 ? 2.0 - uv_repeat.y : uv_repeat.y;
					uv_repeat.y = uv_repeat.y < 0.0 ? -1.0 * uv_repeat.y : uv_repeat.y;

					//
					vec4 _tLogo = texture2D( tLogo, uv_repeat );
					vec4 _tFloor = texture2D( tFloor, uv_repeat );
					vec3 _tFloorLogo = mix( _tFloor.rgb, _tLogo.rgb, mixRatio );
					vec3 _tMix = mix( _tFloorLogo.rgb, color.rgb, mixRatioColor );

					//
					float r = max( _tMix.r - dark, 0.025 );
					float g = max( _tMix.g - dark, 0.025 );
					float b = max( _tMix.b - dark, 0.025 );
					gl_FragColor = vec4( r, g, b, alpha );


				}
			`,
			uniforms: {

				tFloor : { value : RESOURCE.textures.floor.normal.tex },
				tLogo  : { value : RESOURCE.textures.logo.normal.tex },

				//
				scale : { value : { x : 0.25, y :0.25 } },
				alpha : { value : 1.0 },
				color : { value : COLOR_ORIGIN.floor.three },

				//
				dark : { value : this.param.logoSet.floor.uniforms.dark.value },

				//
				mixRatioColor : { value : 0 },
				mixRatio : { value : 1 },

			},
			transparent: true
		});
		this.logoSet.floor.geometry = new THREE.PlaneBufferGeometry( 1, 1, 1, 1 );
		this.logoSet.floor.mesh = new THREE.Mesh( this.logoSet.floor.geometry, this.logoSet.floor.material );
		this.logoSet.group.add( this.logoSet.floor.mesh );


		//==========================
		// Mirror
		//==========================
		this.logoSet.mirror.geometry = new THREE.PlaneBufferGeometry( 1, 1, 1, 1 );
		const textureWidth  = clamp( window.innerHeight, 512, 1024 );
		const textureHeight = clamp( window.innerHeight, 512, 1024 );
		this.logoSet.mirror.mesh = new Reflector( this.logoSet.mirror.geometry, {
			clipBias: 0,
			textureWidth : textureWidth,
			textureHeight: textureHeight,
			color : this.param.logoSet.floor.uniforms.color.value
		});
		this.logoSet.mirror.mesh.rotation.x = 90 * ( Math.PI / 180 );
		this.logoSet.mirror.mesh.rotation.y = 180 * ( Math.PI / 180 );
		this.logoSet.mirror.mesh.material.uniforms.blur.value.x = 0.05;
		this.logoSet.mirror.mesh.material.uniforms.blur.value.y = 0.05;
		this.logoSet.group.add( this.logoSet.mirror.mesh );

		//
		this.scene.add( this.logoSet.group );
		this.scene.add( this.stage );

		//
		this.setParm();

		//
		this.initLight();

		//
		this.setTransform( ROUTE.current.type );

	}

	setParm(){

		//
		this.param.logoSet.group.scale.value = this.param.logoSet.group.scale.home;
		this.param.logoSet.group.position.value.x = this.param.logoSet.group.position.home.x;
		this.param.logoSet.group.position.value.y = this.param.logoSet.group.position.home.y;
		this.param.logoSet.group.position.value.z = this.param.logoSet.group.position.home.z;

		//
		this.param.logoSet.all.position.value.x = this.param.logoSet.all.position.home.x;
		this.param.logoSet.all.position.value.y = this.param.logoSet.all.position.home.y;
		this.param.logoSet.all.position.value.z = this.param.logoSet.all.position.home.z;

		//
		this.param.logoSet.cai.position.value.x = this.param.logoSet.cai.position.home.x;
		this.param.logoSet.cai.position.value.y = this.param.logoSet.cai.position.home.y;
		this.param.logoSet.cai.position.value.z = this.param.logoSet.cai.position.home.z;


		//
		this.param.light.top.position.value.x = this.param.light.top.position.home.x;
		this.param.light.top.position.value.y = this.param.light.top.position.home.y;
		this.param.light.top.position.value.z = this.param.light.top.position.home.z;

	}

	initLight(){

		this.lights.top = new THREE.PointLight( 0xffffff );
		this.lights.top.intensity = 1.5;
		this.lights.top.distance  = 1;
		this.lights.top.penumbra  = 1;
		this.lights.top.decay     = 1;
		this.stage.add( this.lights.top );

	}

	onTransform( _key = 'home', _s = 3, _e1 = 'power2.inOut', _e2 = 'power2.inOut' ){

		//
		killTween( this.tween ); this.tween = [];

		//===============
		// GROUP
		//===============
		let tl = gsap.to( this.param.logoSet.group.scale, {
			duration : _s,
			ease : _e1,
			value : this.param.logoSet.group.scale[ _key ]
		});
		this.tween.push(tl);

		tl = gsap.timeline();
		tl.to( this.param.logoSet.group.rotation.value, {
			duration : _s,
			ease : _e1,
			x : this.param.logoSet.group.rotation[ _key ].x * 1.05,
			y : this.param.logoSet.group.rotation[ _key ].y * 1.05,
			z : this.param.logoSet.group.rotation[ _key ].z * 1.05
		});
		tl.to( this.param.logoSet.group.rotation.value, {
			duration : _s,
			ease : 'power1.out',
			x : this.param.logoSet.group.rotation[ _key ].x,
			y : this.param.logoSet.group.rotation[ _key ].y,
			z : this.param.logoSet.group.rotation[ _key ].z
		});
		this.tween.push(tl);

		tl = gsap.to( this.param.logoSet.group.position.value, {
			duration : _s,
			ease : _e1,
			x : this.param.logoSet.group.position[ _key ].x,
			y : this.param.logoSet.group.position[ _key ].y,
			z : this.param.logoSet.group.position[ _key ].z
		});
		this.tween.push(tl);


		//===============
		// ALL
		//===============
		tl = gsap.to( this.param.logoSet.all.position.value, {
			duration : _s,
			ease : _e1,
			x : this.param.logoSet.all.position[ _key ].x,
			y : this.param.logoSet.all.position[ _key ].y,
			z : this.param.logoSet.all.position[ _key ].z
		});
		this.tween.push(tl);
		// var tl = gsap.to( this.param.logoSet.all.roughness, {
		// 	duration : _s,
		// 	ease : _e1,
		// 	value : this.param.logoSet.all.roughness[ _key ]
		// });
		// this.tween.push(tl);
		// var tl = gsap.to( this.param.logoSet.all.metalness, {
		// 	duration : _s,
		// 	ease : _e1,
		// 	value : this.param.logoSet.all.metalness[ _key ]
		// });
		// this.tween.push(tl);

		//===============
		// CAI
		//===============
		tl = gsap.to( this.param.logoSet.cai.position.value, {
			duration : _s,
			ease : _e1,
			x : this.param.logoSet.cai.position[ _key ].x,
			y : this.param.logoSet.cai.position[ _key ].y,
			z : this.param.logoSet.cai.position[ _key ].z
		});
		this.tween.push(tl);
		tl = gsap.to( this.param.logoSet.cai.rotation.value, {
			duration : _s,
			ease : _e1,
			x : this.param.logoSet.cai.rotation[ _key ].x,
			y : this.param.logoSet.cai.rotation[ _key ].y,
			z : this.param.logoSet.cai.rotation[ _key ].z
		});
		this.tween.push(tl);
		// var tl = gsap.to( this.param.logoSet.cai.roughness, {
		// 	duration : _s,
		// 	ease : _e1,
		// 	value : this.param.logoSet.cai.roughness[ _key ]
		// });
		// this.tween.push(tl);
		// var tl = gsap.to( this.param.logoSet.cai.metalness, {
		// 	duration : _s,
		// 	ease : _e1,
		// 	value : this.param.logoSet.cai.metalness[ _key ]
		// });
		// this.tween.push(tl);


		//===============
		// LIGHT
		//===============
		tl = gsap.to( this.param.light.top.position.value, {
			duration : _s,
			ease : _e1,
			x : this.param.light.top.position[ _key ].x,
			y : this.param.light.top.position[ _key ].y,
			z : this.param.light.top.position[ _key ].z
		});
		this.tween.push(tl);
		// var tl = gsap.timeline();
		// tl.to( this.param.light.top, {
		// 	duration : _s * 0.5,
		// 	ease : 'expo.in',
		// 	intensity : 10
		// });
		// tl.to( this.param.light.top, {
		// 	duration : _s * 0.5,
		// 	ease : 'expo.out',
		// 	intensity : 1.5
		// });

		//===============
		// FLOOR
		//===============
		// if( _key === 'home' ){
		// 	_e = 'expo.out';
		// }
		tl = gsap.to( this.param.logoSet.floor.uniforms.mixRatio, {
			duration : _s,
			ease : _e2,
			value : this.param.logoSet.floor.uniforms.mixRatio[ _key ]
		});
		this.tween.push(tl);
		tl = gsap.to( this.param.logoSet.floor.uniforms.alpha, {
			duration : _s,
			ease : _e2,
			value : this.param.logoSet.floor.uniforms.alpha[ _key ]
		});
		this.tween.push(tl);

		//
		tl = gsap.timeline();
		tl.to( this.param.logoSet.floor.uniforms.mixRatioColor, {
			duration : _s/2,
			ease : _e2,
			value : 1
		});
		tl.to( this.param.logoSet.floor.uniforms.mixRatioColor, {
			duration : _s/2,
			ease : _e2,
			value : 0
		});
		this.tween.push(tl);

	}

	setTransform( _key = 'home' ){

		// var _key = 'home';
		// if( this.isHome ){
		// 	this.isHome = false;
		// 	_key = 'about';
		// } else {
		// 	this.isHome = true;
		// }

		//===============
		// GROUP
		//===============
		this.param.logoSet.group.scale.value = this.param.logoSet.group.scale[ _key ];

		//
		this.param.logoSet.group.position.value.x = this.param.logoSet.group.position[ _key ].x;
		this.param.logoSet.group.position.value.y = this.param.logoSet.group.position[ _key ].y;
		this.param.logoSet.group.position.value.z = this.param.logoSet.group.position[ _key ].z;

		//===============
		// ALL
		//===============
		this.param.logoSet.all.position.value.x = this.param.logoSet.all.position[ _key ].x;
		this.param.logoSet.all.position.value.y = this.param.logoSet.all.position[ _key ].y;
		this.param.logoSet.all.position.value.z = this.param.logoSet.all.position[ _key ].z;

		//===============
		// CAI
		//===============
		this.param.logoSet.cai.position.value.x = this.param.logoSet.cai.position[ _key ].x;
		this.param.logoSet.cai.position.value.y = this.param.logoSet.cai.position[ _key ].y;
		this.param.logoSet.cai.position.value.z = this.param.logoSet.cai.position[ _key ].z;
		this.param.logoSet.cai.rotation.value.x = this.param.logoSet.cai.rotation[ _key ].x;
		this.param.logoSet.cai.rotation.value.y = this.param.logoSet.cai.rotation[ _key ].y;
		this.param.logoSet.cai.rotation.value.z = this.param.logoSet.cai.rotation[ _key ].z;

		//===============
		// LIGHT
		//===============
		this.param.light.top.position.value.x = this.param.light.top.position[ _key ].x;
		this.param.light.top.position.value.y = this.param.light.top.position[ _key ].y;
		this.param.light.top.position.value.z = this.param.light.top.position[ _key ].z;

		//===============
		// FLOOR
		//===============
		this.param.logoSet.floor.uniforms.mixRatio.value = this.param.logoSet.floor.uniforms.mixRatio[ _key ];
		this.param.logoSet.floor.uniforms.alpha.value = this.param.logoSet.floor.uniforms.alpha[ _key ];

		//
		this.param.logoSet.floor.uniforms.mixRatioColor.value = 0;

	}

	value( v, xyz = false ){

		if( !xyz ){
			v.value = v.about * ROUTE.pages.about + v.home * ROUTE.pages.home;
			return v.value;
		} else {
			v.value[xyz] = v.about[xyz] * ROUTE.pages.about + v.home[xyz] * ROUTE.pages.home;
			return v.value[xyz];
		}

	}

	update(){

		if( this.logoSet.all.mesh ){

			//
			const scale = window.innerWidth * 0.01 * this.value( this.param.logoSet.group.scale ) * RESCALE.r; // this.param.logoSet.all.scale

			//
			this.logoSet.group.position.x = this.value( this.param.logoSet.group.position, 'x' ) * window.innerWidth * RESCALE.r * 0.1;
			this.logoSet.group.position.y = this.value( this.param.logoSet.group.position, 'y' ) * window.innerWidth * RESCALE.r * 0.1;
			this.logoSet.group.position.z = this.value( this.param.logoSet.group.position, 'z' ) * window.innerWidth * RESCALE.r * 0.1;
			this.logoSet.group.rotation.x = this.value( this.param.logoSet.group.rotation, 'x' ) * ( Math.PI / 180 );
			this.logoSet.group.rotation.y = this.value( this.param.logoSet.group.rotation, 'y' ) * ( Math.PI / 180 );
			this.logoSet.group.rotation.z = this.value( this.param.logoSet.group.rotation, 'z' ) * ( Math.PI / 180 );

			//==========================
			// ALL
			//==========================
			this.logoSet.all.mesh.scale.x = scale * this.param.logoSet.all.scale;
			this.logoSet.all.mesh.scale.y = scale * this.param.logoSet.all.scale;
			this.logoSet.all.mesh.scale.z = scale * this.param.logoSet.all.scale;
			// this.logoSet.all.mesh.material.roughness = this.param.logoSet.all.roughness.value;
			// this.logoSet.all.mesh.material.metalness = this.param.logoSet.all.metalness.value;
			this.logoSet.all.mesh.rotation.x = this.value( this.param.logoSet.all.rotation, 'x' ) * ( Math.PI / 180 );
			this.logoSet.all.mesh.rotation.y = this.value( this.param.logoSet.all.rotation, 'y' ) * ( Math.PI / 180 );
			this.logoSet.all.mesh.rotation.z = this.value( this.param.logoSet.all.rotation, 'z' ) * ( Math.PI / 180 );
			this.logoSet.all.mesh.position.x = scale * this.value( this.param.logoSet.all.position, 'x' );
			this.logoSet.all.mesh.position.y = scale * this.value( this.param.logoSet.all.position, 'y' );
			this.logoSet.all.mesh.position.z = scale * this.value( this.param.logoSet.all.position, 'z' );


			//==========================
			// CAI
			//==========================
			this.logoSet.cai.mesh.scale.x = scale * this.param.logoSet.cai.scale;
			this.logoSet.cai.mesh.scale.y = scale * this.param.logoSet.cai.scale;
			this.logoSet.cai.mesh.scale.z = scale * this.param.logoSet.cai.scale;
			// this.logoSet.cai.mesh.material.roughness = this.param.logoSet.cai.roughness.value;
			// this.logoSet.cai.mesh.material.metalness = this.param.logoSet.cai.metalness.value;
			this.logoSet.cai.mesh.rotation.x = this.value( this.param.logoSet.cai.rotation, 'x' )  * ( Math.PI / 180 );
			this.logoSet.cai.mesh.rotation.y = this.value( this.param.logoSet.cai.rotation, 'y' )  * ( Math.PI / 180 );
			this.logoSet.cai.mesh.rotation.z = this.value( this.param.logoSet.cai.rotation, 'z' )  * ( Math.PI / 180 );
			this.logoSet.cai.mesh.position.x = scale * this.value( this.param.logoSet.cai.position, 'x' );
			this.logoSet.cai.mesh.position.y = scale * this.value( this.param.logoSet.cai.position, 'y' );
			this.logoSet.cai.mesh.position.z = scale * this.value( this.param.logoSet.cai.position, 'z' );


			//
			if( this.logoSet.floor.mesh ){

				//
				this.logoSet.floor.mesh.scale.x    = scale * this.param.logoSet.floor.scale * 2;
				this.logoSet.floor.mesh.scale.y    = scale * this.param.logoSet.floor.scale * 2;
				this.logoSet.floor.mesh.scale.z    = scale * this.param.logoSet.floor.scale * 2;
				this.logoSet.floor.mesh.rotation.x = this.param.logoSet.floor.rotation.value.x * ( Math.PI / 180 );
				this.logoSet.floor.mesh.rotation.y = this.param.logoSet.floor.rotation.value.y * ( Math.PI / 180 );
				this.logoSet.floor.mesh.rotation.z = this.param.logoSet.floor.rotation.value.z * ( Math.PI / 180 );

				//
				this.logoSet.floor.mesh.position.x = scale * this.param.logoSet.floor.position.value.x;
				this.logoSet.floor.mesh.position.y = scale * this.param.logoSet.floor.position.value.y;
				this.logoSet.floor.mesh.position.z = scale * this.param.logoSet.floor.position.value.z;

				//
				if( this.logoSet.mirror.mesh ){
					this.logoSet.mirror.mesh.scale.x = scale * 30;
					this.logoSet.mirror.mesh.scale.y = scale * 30;
					this.logoSet.mirror.mesh.position.y = this.logoSet.floor.mesh.position.y - 0.01;
				}

				//==================
				// Uniforms
				//==================
				this.logoSet.floor.mesh.material.uniforms.alpha.value         = this.value( this.param.logoSet.floor.uniforms.alpha );
				this.logoSet.floor.mesh.material.uniforms.mixRatio.value      = this.value( this.param.logoSet.floor.uniforms.mixRatio );
				this.logoSet.floor.mesh.material.uniforms.mixRatioColor.value = this.value( this.param.logoSet.floor.uniforms.mixRatioColor );

			}


			//
			this.lights.top.position.x = this.value( this.param.light.top.position, 'x' ) * window.innerWidth * RESCALE.r;
			this.lights.top.position.y = this.value( this.param.light.top.position, 'y' ) * window.innerWidth * RESCALE.r;
			this.lights.top.position.z = this.value( this.param.light.top.position, 'z' ) * window.innerWidth * RESCALE.r;
			this.lights.top.intensity  = this.param.light.top.intensity;
			this.lights.top.distance   = window.innerWidth * this.param.light.top.distance;
			this.lights.top.decay      = this.param.light.top.decay;

		}

	}

}