import Vue from 'vue';
Vue.mixin({
	methods: {
		onStaicRouteTo( e ){
			const el = e.target;
			const path = el.getAttribute('href');
			const to = el.dataset.to;
			if( path ){
				ROUTE.onStaicRouteTo( path, this.$router, to );
			}
		},
		onStaicSwitchLang( e ){
			const el = e.target;
			const path = el.getAttribute('href');
			const to = el.dataset.to;
			if( path ){
				ROUTE.onStaicRouteTo( path, this.$router, 'lang' );
			}
		},
		onCopyToClipboard( e ){
			const el = e.target;
			const mail = el.dataset.mail;
			if( mail ){
				if( !this.$device.isDesktop ){
					location.href = "mailto:"+mail;
				} else {
					this.$copyText( mail );
					el.classList.add('is-copied');
					setTimeout(()=>{
						el.classList.remove('is-copied');
					},1000);
				}
			}
		}
	}
});
